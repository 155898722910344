import React from 'react';
import Typical from 'react-typical';
import './Profile.css';

export default function Profile() {
  return (
    <div className="profile-container ">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a href="https://www.facebook.com/aneeb.akbar.52">
                <i className="fa fa-facebook-square"></i>
              </a>
              &nbsp;
              <a href="https://www.instagram.com/aneeb_akbar/">
                <i className="fa fa-instagram"></i>
              </a>
              &nbsp;
              <a href="https://www.linkedin.com/in/aneeb-akbar-a3349217a/">
                <i className="fa fa-linkedin-square"></i>
              </a>
              &nbsp;
              <a href="https://github.com/aneebakbar12">
                <i className="fa fa-github-square"></i>
              </a>
              &nbsp;
            </div>
          </div>

          <div className="profile-details-name">
            <span className="primary-text">
              {' '}
              Hello,I'm<span className="highlighted-text"> Aneeb</span>
            </span>
          </div>

          <div className="profile-details-role">
            <span className="primary-text">
              {' '}
              <h1>
                {' '}
                <Typical
                  loop={Infinity}
                  steps={[
                    'Web Developer 🌐',
                    1000,
                    'Full Stack Developer 🔥',
                    1000,
                    'MERN Stack Developer 🤑',
                    1000,
                    'Game Developer 🎮',
                    1000,
                  ]}
                />
              </h1>
              <span className="profile-role-tagline">
                Knack of buiding applications with frontend and backend
                operations.
              </span>
            </span>
          </div>
          <div className="profile-optins">
            <a href="AneebsResume.pdf" download="Aneeb AneebsResume.pdf">
              <button className="btn highlighted-btn">Get Resume</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
