import './App.css';
import Aboutme from './PortfolioContainer/Aboutme/Aboutme';
import Contact from './PortfolioContainer/Contactme/Contact';
import Ending from './PortfolioContainer/Ending/Ending';
import Home from './PortfolioContainer/Home/Home';


function App() {
  return (
    <div className="App">
  
   <Home/>
   <Aboutme/>
   <Contact/>
   <Ending/>
    </div>
  );
}

export default App;
