import React from 'react'
import "./Contact.css";
import emailjs from 'emailjs-com'
function Contact() {
    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm('service_4bprf8j','template_685fuz1' , e.target , 'B88Yy622fcVgT6s5v').then(res=>{
            console.log(res);

        }).catch(err=> console.log(err));
    }

  return (
    <div className='contact componet__space'>
        <div className='row'>
            <div className='col__2'>
                <div className='contact__box'>
                    <div className='contact__meta'>
                        <h1 className='hire__text'>Hire Me</h1>
                        <p className=' about_text p_color'>I am avaliable for freelancing work.Connectwith me via phone</p>
     <p className='about_text p_color '><strong>+923234587777</strong> or email <strong>aneeb458@gmail.com</strong></p>
                    </div>
                    <form className="row"  onSubmit={sendEmail}>
                    <div className='input__box'>
                        <input type="text" name="name" className='contact name' placeholder='Your Name'/>
                        <input type="text" name="user_email" className='contact email' placeholder='Your Email'/>
                        {/* <input type="text" className='contact subject' placeholder='Your Subject'/> */}
                        <input type="text" name='message' className='contact message' placeholder='Your Message'/>
                       <button className='btn highlighted-btn' type='submit'>Submit</button>
                    </div>


                    {/* <label>Name</label>
                    <input type="text" name="name" className='from-control'/>
                    <label>Email</label>
                    <input type="email" name="user_email" className='from-control'/>
                    <label>Message</label>
                    <textarea name='message' rows='4'/>
                    <input type='submit' value='send'/> */}


                    </form>

                </div>
            </div>
        </div>

    </div>
  )
}

export default Contact