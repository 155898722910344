import React from 'react'
import './Ending.css'
export default function Ending() {
  return (
    <div>
      
<footer class="text-center text-lg-start bg-light text-muted">
 
  <section
    class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
  >
   
    <div class="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
   

    <div className='colz'>
    <div className='colz-icon'>

                    <a href='https://www.facebook.com/aneeb.akbar.52'>
                        <i className='fa fa-facebook-square'></i>
                        
                    </a>
                    &nbsp;
                  
                    
                    <a href='https://www.instagram.com/aneeb_akbar/'>
                        <i className='fa fa-instagram'></i>
                    </a>
                    &nbsp;
                    <a href='https://www.linkedin.com/in/aneeb-akbar-a3349217a/'>
                        <i className='fa fa-linkedin-square'></i>
                    </a>
                    &nbsp;
                    <a href='https://github.com/aneebakbar12'>
                        <i className='fa fa-github-square'></i>
                    </a>
                    &nbsp;
                    </div>
                    </div>

  </section>
 

 
  <section class="">
    <div class="container text-center text-md-start mt-5">
     
      <div class="row mt-3">
      
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
         
          <h7 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3" ></i>Web2technologies
          </h7>
          <p>
            hello world
          </p>
        </div>
     
       
       
       
      
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
        
          <h7 class="text-uppercase fw-bold mb-4">
            Contact
          </h7>
          <p><i class="fas  me-3"></i> 🏢Johar Town,Lahore</p>
          <p>
            <i class="fas  me-3"></i>
            📧aneeb458@gmail.com
          </p>
          <p><i class="fas  me-3"></i> ☎️+923234587777</p>
          
        </div>
       
      </div>
    
    </div>
  </section>




 
</footer>





    </div>
  )
}
