import React from 'react'
import './Shape.css'
export default function Shape() {
  return (
    <div className='footer-container'>
        <div className='footer-parent'>
            <img src={require('../../../assets/Home/shape-bg.png')} alt='no internet connection'/>

        </div>
    </div>  
  )
}
