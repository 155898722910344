import React from 'react'
import Shape from './Shape/Shape'
import Profile from './Profile'
import Navbar from '../Navbar/Navbar'

export default function Home() {
  return (
    <div className='home-container'>
      {/* <Navbar/> */}
        <Profile/>
       <Shape/>
    </div>
  )
}
