import React from 'react'
import "./Aboutme.css";
import aboutImg from '../../assets/Home/IMG_6594.jpg';
import bgg from '../../assets/Home/bg.jpg';
export default function Aboutme() {
  return (

    <div className='about component_space'>
        <div className='container'>
            <div className='row'>
                <div className='col__2'>
                    <img src={aboutImg} alt='' className="about_img"  />
                </div>
               <div className='col__2'>
                   <h1 className='about__heading'>
                       About Me
                   </h1>
                   <div className='about_meta'>
                       <p className='about_text p_color'>
                          I am MERN Stack developer.
                         I have 3 years of experiece in MERN.Before this I have also worked on Flask and made many projects on it 
                         all of the projects are on my github.I have worked on Nextjs also and doing full time work on MERN.

                       </p>
                       <p className='about_text p_color'>
                          I have done bachelor in Computer Science and doing freelancing from the past 4 years.
                          All my clients are of MERN and FLASK I have also made dozens of my personal projects and also worked for my clients.
                         <br/> Some of my Projects are : 
                         <br/>
                         Coding Thunder<br/>
                         E-Store<br/>
                         Social-Media-App<br/>
                         Udaar App<br/>
                         CV Maker<br/>
                      
                       </p>
                   </div>
               </div>
            </div>
        </div>
        <div className='footer-container  '>
        <div className='footer-parent' >
        <div className='col__2'>
                  
                </div>
            <img src={require('../../assets/Home/shape-bg.png')} alt='no internet connection'/>

        </div>
    </div>  


























































    {/* <div className='text' >
  <head>
  <link rel="stylesheet" href="./style.css"/>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <script src="https://kit.fontawesome.com/bdd89edb33.js"></script>
    <link
      href="https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900"
      rel="stylesheet"
    />
    <link rel="stylesheet" href="css/style.css" />
  
  </head>

    <section class="section-plans" id="section-plans">
      <div class="u-center-text u-margin-bottom-big">
        <h2 class="heading-secondaryh2">
          About Me
        </h2>
      </div>

      <div class="row">
        <div class="col-1-of-3">
        
        </div>

        <div class="col-1-of-3">
          <div class="card">
            <div class="card__side card__side--front-2">
              <div class="card__title card__title--2">
                <i class="fas fa-plane"></i>
                <h4 class="card__heading">MERN Stack</h4>
              </div>

              <div class="card__details">
                <ul>
                  <li>test</li>
                 <li>test</li>
                 <li>test</li>
                 <li>test</li>
                 <li>test</li>
                 <li>test</li>

                </ul>
              </div>
            </div>
            <div class="card__side card__side--back card__side--back-2">
              <div class="card__cta">
                <div class="card__price-box">
                  <p class="card__price-only">Only</p>
                  <p class="card__price-value">test</p>
                </div>
                <a href="#popup" class="btn btn--white">Select</a>
              </div>
            </div>
          </div>
        </div>

      
      </div>

     
    </section>



  













        </div> */}
        </div>
  )
}
